.o-header {
  height: 130px;
  padding-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (min-width: 1024px) {
    padding-top: 50px;
  }
}
