.o-contact {
  &__form {
    margin-top: 15px;
    z-index: 2;
    position: relative;
    @media (min-width: 1024px) {
      margin-top: 30px;
    }
    display: flex;
    flex-direction: column;
    input,
    textarea {
      font-family: "Poppins";
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 20px;
      color: #97a8a6;
      background-color: #1f2828;
      border-color: transparent;
      &:focus,
      &:active {
        outline-color: #ff9800;
      }
    }
    textarea {
      min-height: 104px;
    }
    &-button {
      height: 52px;
      font-size: 16px;
    }
    &-thankyou {
      margin-top: 15px;
      transform-origin: 50% 0;
      animation: thank-animate 0.4s linear;
      @media (min-width: 1024px) {
        margin-top: 30px;
      }
    }
    @keyframes thank-animate {
      from {
        transform: scaleY(0);
        opacity: 0;
      }
      to {
        transform: scaleY(1);
        opacity: 1;
      }
    }
  }
}
