.a-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  text-transform: uppercase;
  @media (min-width: 468px) {
    font-size: 36px;
  }
  @media (min-width: 1024px) {
    font-size: 72px;
    line-height: 100px;
  }
}
