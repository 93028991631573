.a-btn {
  padding: 0 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(269.93deg, #ffb156 4.72%, #f07f42 99.91%);
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #fff;
  cursor: pointer;
  width: fit-content;
  height: 60px;
  border: 1px solid transparent;
  border-radius: 200px;
  text-transform: uppercase;
  flex-grow: 1;
  &:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABWSURBVHgB5cxBDQAhDATA9hQg4SSclHMCEnCCJHCABHBQNqEPHgTehE02TdNJiRARsehLqwA46clLjKNB41X4mUFmrhhJV6Odfgz6raDfSejfogH7HWo6fuAm241aDAAAAABJRU5ErkJggg==);
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 11px;
    transition: 0.25s;
  }
  &:hover:after {
    transform: translateX(15px);
  }
  &:disabled {
    background: #444;
    cursor: not-allowed;
  }
}
