@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Poppins";
  src: local("PoppinsRegular"),
    url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
.a-font-face {
  font-family: "Poppins";
}
.orange {
  color: #ff9800;
}
.white {
  color: #fff;
}
.green {
  color: #97a8a6;
}
.gray {
  color: #222;
}
