.a-container {
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1280px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: 1366px) {
    padding-left: 250px;
    padding-right: 250px;
  }

  @media (min-width: 1920px) {
    padding-left: 313px;
    padding-right: 313px;
  }
}
