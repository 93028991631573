* {
  box-sizing: border-box;
}
html,
body {
  text-rendering: optimizeLegibility;
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  min-height: 100vh;
  background-color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
dl,
dd,
dt {
  padding: 0;
  margin: 0;
  line-height: 1;
}
ul {
  list-style-type: none;
}
address {
  font-style: normal;
}
a {
  text-decoration: none;
}
.main {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
  canvas {
    position: absolute;
    z-index: 1;
  }
}
#desktopNav {
  display: none;
  z-index: 1;
  @media (min-width: 1024px) {
    display: block;
  }
}
#mobileNav {
  @media (min-width: 1024px) {
    display: none;
  }
}
