.a-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  &--small {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 30px;
    @media (min-width: 448px) {
      font-size: 14px;
    }
  }
  &--extrasmall {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 30px;
    @media (min-width: 448px) {
      font-size: 10px;
    }
  }
}
