.a-link {
  color: #fff;
  transition: 0.25s;
  &:hover {
    color: #ff9800;
  }
  &--active {
    color: #ff9800;
  }
}
