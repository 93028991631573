.a-loading {
  width: 2rem;
  height: 2rem;
  border: 5px solid #fff;
  border-top: 6px solid #ff9800;
  border-radius: 100%;
  margin: auto;
  visibility: hidden;
  animation: spin 1s infinite linear;
  position: absolute;
  left: 0;
  right: 0;
  &.display {
    visibility: visible;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
