.m-nav {
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    gap: 20px;
  }
  &--mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: black;
    transform: translateX(100%);
    transition: 0.5s;
    &.active {
      transform: translateX(0);
    }
    @media (min-width: 1024px) {
      display: none;
    }
    .a-link,
    .a-link--active {
      font-size: 30px;
      line-height: 1.5;
    }
  }
}
