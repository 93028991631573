.a-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  @media (min-width: 468px) {
    font-size: 19px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
  }
}
