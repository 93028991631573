.o-footer {
  background-color: rgba(69, 69, 69, 0.4);
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  p {
    color: #c4c4c4;
    a {
      color: #fff;
    }
  }
}
