.m-columns-50 {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 50px;
  }
  &__item {
    position: relative;
    .fa.fa-terminal {
      margin-right: 10px;
    }
    line-height: 1.5;
    @media (min-width: 768px) {
      flex-basis: 48%;
      justify-content: space-between;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 20px;
    }
    &--contact {
      margin-top: 15px;
      z-index: 2;
      @media (min-width: 1024px) {
        margin-top: 30px;
      }
      .fa-envelope,
      .fa-mobile,
      .fa-id-card {
        color: #ff9800;
        font-size: 22px;
        text-align: center;
        margin-right: 20px;
        width: 22px;
      }
      .contact-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding: 15px;
        border-radius: 20px;
        color: #97a8a6;
        background-color: #1f2828;
        a {
          color: #97a8a6;
          transition: 0.25s;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
    &--img {
      margin-top: 15px;
      @media (min-width: 1024px) {
        margin-top: 30px;
      }
    }
  }
}
