.a-wrapper {
  background: #141818;
  border-radius: 30px;
  margin-top: 30px;
  padding: 30px;
  margin-bottom: 30px;
  transition: 0.3s;
  @media (min-width: 768px) {
    min-height: 508px;
    margin-top: 60px;
    padding: 50px;
  }
}
