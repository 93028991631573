.p-homepage {
  padding-bottom: 150px;
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  .a-subtitle {
    width: 270px;
    @media (min-width: 468px) {
      width: 320px;
    }
    @media (min-width: 768px) {
      width: 420px;
    }
  }
  .a-btn {
    margin-top: 30px;
  }
}
