.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  width: auto;
  height: 300px;
  border-radius: 20px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.m-swiper {
  &__slide {
    position: relative;
    &:hover {
      .m-swiper__slide-desc {
        opacity: 1;
      }
    }
    &-desc {
      position: absolute;
      top: 25px;
      background: #222;
      border-radius: 20px;
      padding: 25px;
      width: 80%;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      transition: 0.2s;
      p {
        color: white;
        margin-bottom: 15px;
      }
      a {
        box-shadow: inset 0 0 0 0 #a2a2a2;
        color: #a2a2a2;
        margin: 0 -0.25rem;
        padding: 0 0.25rem;
        transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        border-radius: 10px;
        padding: 5px;
      }
      a:hover {
        box-shadow: inset 100px 0 0 0 #a2a2a2;
        color: white;
      }
    }
  }
}
