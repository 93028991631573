.a-change-subtitle {
  position: relative;
}
.a-change-text {
  position: absolute;
  margin-left: 5px;
  opacity: 0;
  transition: 0.5s;
  &.is-active {
    opacity: 1;
  }
}
